import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import "./Faq.css";

export const faqs = [
  {
    question: "What services does Prima Consultancy & Constructions offer?",
    answer:
      "Prima Consultancy & Constructions offers comprehensive services in construction, project management, and architectural consultation, specializing in both residential and commercial projects.",
  },
  {
    question:
      "How does Prima Consultancy & Constructions ensure project quality and on-time delivery?",
    answer:
      "Prima Consultancy & Constructions uses advanced project management tools and has a skilled team dedicated to maintaining quality and adhering to project timelines, ensuring client satisfaction on every project.",
  },
  {
    question:
      "Can Prima Consultancy & Constructions handle large-scale commercial construction projects?",
    answer:
      "Yes, Prima Consultancy & Constructions has extensive experience managing large-scale commercial projects, including office buildings, retail complexes, and hospitality venues, from planning to completion.",
  },
  {
    question: "How can I get a quote for a construction project?",
    answer:
      "You can request a quote by contacting us through our website’s contact form or by calling us directly. Our team will discuss your project requirements and provide a detailed estimate.",
  },
  {
    question:
      "Does Prima Consultancy & Constructions offer eco-friendly building options?",
    answer:
      "Absolutely. Prima Consultancy & Constructions integrates sustainable practices and materials in our projects, offering eco-friendly solutions to clients who value environmental sustainability.",
  },
  {
    question:
      "Where is Prima Consultancy & Constructions located, and what areas do you serve?",
    answer:
      "Prima Consultancy & Constructions is based in Lucknow, Uttar Pradesh, and serves clients across the state and neighboring regions, with the capability to manage projects nationally and globally.",
  },
];

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFaq = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <section id="faq" className="faq-container">
      <h2>Frequently Asked Questions</h2>
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <h3 onClick={() => toggleFaq(index)} style={{ cursor: "pointer" }}>
            <span>
              {activeIndex === index ? <FaChevronUp /> : <FaChevronDown />}
            </span>{" "}
            {faq.question}
          </h3>
          {activeIndex === index && <p className="faq-answer">{faq.answer}</p>}
        </div>
      ))}
    </section>
  );
};

export default Faq;
