import React, { useState } from "react";
import "./Contact.css";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { FaLocationCrosshairs } from "react-icons/fa6";
import { FiPhoneCall } from "react-icons/fi";

const ContactSection = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (
      !formData.fullName ||
      !formData.email ||
      !formData.phoneNumber ||
      !formData.message
    ) {
      alert("Please fill in all fields");
      return;
    }

    try {
      const response = await fetch(
        "https://emailresponse.onrender.com/send-email",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        }
      );

      const result = await response.json();
      if (result.success) {
        alert("Message sent successfully!");
        setFormData({
          fullName: "",
          email: "",
          phoneNumber: "",
          message: "",
        });
      } else {
        alert("Failed to send message.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("There was an error sending your message.");
    }
  };

  return (
    <section id="contact">
      <div className="overlay__div">
        <div className="container">
          <div className="flex left__container">
            <div className="contact__info__wrapper">
              <div className="flex contact__info">
                <div className="flex__center icon">
                  <MdOutlineAlternateEmail />
                </div>
                <div className="details">
                  <h4>Email us</h4>
                  <p className="muted">Fast and reliable Support</p>
                  <p>
                    <a href="mailto:info@primaconsultancy.co.in">
                      info@primaconsultancy.co.in
                    </a>
                  </p>
                </div>
              </div>

              <div className="flex contact__info">
                <div className="flex__center icon">
                  <FaLocationCrosshairs />
                </div>
                <div className="details">
                  <h4>Our Address</h4>
                  <p className="muted">Come visit us in our Office</p>
                  <p>Lucknow, Uttar Pradesh</p>
                </div>
              </div>

              <div className="flex contact__info">
                <div className="flex__center icon">
                  <FiPhoneCall />
                </div>
                <div className="details">
                  <h4>Phone Number</h4>
                  <p className="muted">Give us a call</p>
                  <p>+91 7905004609</p>
                </div>
              </div>
            </div>
          </div>

          <form className="form" onSubmit={(e) => e.preventDefault()}>
            <div className="form__top">
              <h3 className="sub__heading">Get in Touch</h3>
              <p className="muted">
                Have a project in mind or need expert advice? We're here to
                help! Reach out to our team for personalized consultations,
                inquiries, or to start your next big project today.
              </p>
            </div>
            <div className="form__middle">
              <input
                type="text"
                placeholder="Full Name"
                name="fullName"
                className="control"
                value={formData.fullName}
                onChange={handleChange}
                required
              />
              <input
                type="email"
                placeholder="Email Address"
                name="email"
                className="control"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <input
                type="tel"
                placeholder="Phone Number"
                name="phoneNumber"
                className="control"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
              />
              <textarea
                name="message"
                placeholder="Your message"
                className="control"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <div className="form__bottom">
              <button
                type="button"
                className="btn primary"
                onClick={handleSubmit}
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
