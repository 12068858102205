import React from "react";
import "./Testimonials.css";
import { testimonials } from "../../data";
import { FaStar } from "react-icons/fa";
import { FaStarHalfStroke } from "react-icons/fa6";

const Testimonials = () => {
  return (
    <section id="testimonials">
      <div className="container">
        <div className="testimonials__container">
          {testimonials.map((item, index) => (
            <div className="testimonial__card" key={index}>
              <div className="flex top">
                <div className="profile">
                  <img src={item.image} alt="testimonials" />
                </div>
                <div className="details">
                  <h4>{item.name}</h4>
                  <div className="flex star__container">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStarHalfStroke />
                  </div>
                </div>
              </div>
              <div className="bottom">
                <p className="muted">{item.review}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
