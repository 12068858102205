import React from "react";
import "./Portfolio.css";
import { portfolio } from "../../data";

const Portfolio = () => {
  return (
    <section id="portfolio">
      <div className="container">
        <div className="section__header">
          <h3 className="sub__heading">Crafting Spaces, Building Dreams</h3>
          <p className="description">
            At Prima Consultancy and Construction, our portfolio reflects our
            dedication to delivering innovative, high-quality solutions across a
            wide range of projects. From structural designs to architectural
            masterpieces and sustainable landscape developments, each project
            showcases our commitment to precision, innovation, and client
            satisfaction. Explore our work to see how we turn visions into
            reality through expertise and collaboration.
          </p>
        </div>
        <div className="portfolio__container">
          {portfolio.map((item, index) => (
            <div className="flex portfolio" key={index}>
              <div className="image__container">
                <img src={item.image} alt="portfolio" />
              </div>
              <div className="details">
                <h4 className="title">{item.title}</h4>
                <p className="line__clamp__2 description">{item.description}</p>
                <div className="flex date__category">
                  <p className="date">{item.date}</p>
                  <p>{item.category}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
