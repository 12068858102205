import React from "react";
import "./Hero.css";
import Achievement from "../../components/Achievement/Achievement";
import { Link } from "react-scroll";
import { crane } from "../../assets";

const Hero = () => {
  return (
    <section id="hero" className="blur__effect">
      <div className="overlay__div">
        <div className="container">
          <div className="column left">
            <div className="hero__content">
              <h1 className="heading">
                We Build Somethings New And Consistent
              </h1>
              <p className="description">
                At Prima Consultancy and Construction, we provide expert
                services in structural design, retrofitting, structural
                rehabilitation, architecture, landscape design, and surveying.
                With a focus on innovation, quality, and precision, our team is
                dedicated to delivering solutions that empower your projects and
                ensure long-term success. Partner with us to transform your
                vision into reality.
              </p>
            </div>
            <Achievement />
            <div className="flex__center buttons__wrapper">
              <Link
                to="portfolio"
                smooth={true}
                className="flex__center btn primary"
              >
                Projects
              </Link>
              <Link
                to="contact"
                smooth={true}
                className="flex__center btn primary"
              >
                Get a quote
              </Link>
            </div>
          </div>
          <div className="column hero__image-container">
            <img src={crane} alt="crane" className="object__contain" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
