import {
  FaTools,
  FaLightbulb,
  FaHandsHelping,
  FaCheckCircle,
  FaClock,
  FaDraftingCompass,
  FaUserFriends,
  FaInstagram,
  FaLinkedin,
  FaBuilding,
  FaPencilRuler,
  FaMapMarkedAlt,
  FaTasks,
} from "react-icons/fa";
import { FaRegClock, FaTreeCity } from "react-icons/fa6";
import {
  blog1,
  blog2,
  blog3,
  blog4,
  blog5,
  blog6,
  blog7,
  blog8,
  blog9,
  portfolio1,
  portfolio2,
  portfolio3,
  portfolio4,
  portfolio5,
  portfolio6,
  user1,
  user2,
  user3,
  user4,
} from "./assets";

export const navigations = [
  {
    label: "About Us",
    to: "about",
  },
  {
    label: "Services",
    to: "services",
  },
  {
    label: "Portfolio",
    to: "portfolio",
  },
  {
    label: "Testimonials",
    to: "testimonials",
  },
  {
    label: "Contact Us",
    to: "contact",
  },
];

export const keypoints = [
  {
    icon: <FaTools />,
    title: "Expert Craftsmanship",
  },
  {
    icon: <FaRegClock />,
    title: "On-Time, Within Budget",
  },
  {
    icon: <FaLightbulb />,
    title: "Innovative Solutions",
  },
  {
    icon: <FaHandsHelping />,
    title: "Client-Centered Approach",
  },
];

export const whyChooseUs = [
  {
    title: "Unmatched Quality",
    icon: <FaCheckCircle />,
    description: "Craftsmanship guaranteed",
  },
  {
    title: "Timely Delivery",
    icon: <FaClock />,
    description: "Projects completed on schedule",
  },
  {
    title: "Innovative Designs",
    icon: <FaDraftingCompass />,
    description: "Creative and modern solutions",
  },
  {
    title: "Focused Service",
    icon: <FaUserFriends />,
    description: "Prioritizing client satisfaction always",
  },
];

export const services = [
  {
    title: "Structural Design",
    icon: <FaDraftingCompass />,
    description: `Providing reliable and innovative structural solutions for safe and resilient buildings.`,
  },
  {
    title: "Retrofitting & Structural Rehabilitation",
    icon: <FaBuilding />,
    description: `Enhancing and restoring existing structures for improved durability and safety.`,
  },
  {
    title: "Architectural Design",
    icon: <FaPencilRuler />,
    description: `Crafting unique, sustainable designs that bring your vision to life.`,
  },
  {
    title: "Landscape Design",
    icon: <FaTreeCity />,
    description: `Creating beautiful, functional outdoor spaces that harmonize with the environment.`,
  },
  {
    title: "Surveying",
    icon: <FaMapMarkedAlt />,
    description: `Delivering accurate and detailed surveying services for precise project planning.`,
  },
  {
    title: "Project Management",
    icon: <FaTasks />,
    description: `Offering end-to-end project management for smooth execution and timely delivery.`,
  },
];

export const portfolio = [
  {
    title: "Luxury Residential Villa",
    image: portfolio1,
    date: "May 15, 2023",
    category: "Residential",
    description: `A meticulously designed luxury villa with five bedrooms, combining contemporary architecture, expansive windows, and open layouts. Constructed with premium materials and elegant finishes, this home reflects sophistication and comfort.`,
  },
  {
    title: "Modern Corporate Office Complex",
    image: portfolio2,
    date: "November 30, 2022",
    category: "Commercial",
    description: `A cutting-edge corporate office complex built to inspire productivity and collaboration. The design incorporates sustainable materials, spacious working areas, and state-of-the-art meeting rooms.`,
  },
  {
    title: "Shopping and Entertainment Hub",
    image: portfolio3,
    date: "July 12, 2024",
    category: "Retail",
    description: `An expansive multi-level shopping and entertainment complex featuring retail spaces for leading brands, with sophisticated interiors, escalators, and vibrant common areas to enhance the customer experience.`,
  },
  {
    title: "Exclusive Resort & Wellness Spa",
    image: portfolio4,
    date: "February 1, 2023",
    category: "Hospitality",
    description: `A premium 5-star resort and spa nestled in a scenic setting. The project includes infinity pools, private suites, and a wellness center, designed with natural materials for seamless harmony with the surroundings.`,
  },
  {
    title: "Urban High-Rise Apartment Complex",
    image: portfolio5,
    date: "September 18, 2023",
    category: "Residential",
    description: `A high-rise apartment complex offering over 100 modern apartments. Features include a rooftop garden, fitness center, and private balconies with stunning city views, catering to urban living at its best.`,
  },
  {
    title: "Industrial Logistics & Storage Facility",
    image: portfolio6,
    date: "March 5, 2022",
    category: "Industrial",
    description: `A large-scale warehouse optimized for efficient storage and logistics. High ceilings, wide loading docks, and ample storage space ensure smooth operations for industrial clients.`,
  },
];

export const testimonials = [
  {
    name: "Neeraj Kumar",
    image: user1,
    review: `The team at Prima Consultancy and Constructions went above and beyond our expectations. Their attention to detail and professionalism made our building renovation a seamless and satisfying experience.`,
  },
  {
    name: "Priya Menon",
    image: user2,
    review: `From start to finish, Prima Consultancy and Constructions handled the project with remarkable skill and efficiency. I highly recommend them for all construction and design needs.`,
  },
  {
    name: "Rohit Iyer",
    image: user3,
    review: `Working with Prima Consultancy and Constructions was an outstanding experience. Their team was professional, creative, and fully committed to bringing our vision to life.`,
  },
  {
    name: "Sunita Verma",
    image: user4,
    review: `Prima Consultancy and Constructions provided exceptional service and quality craftsmanship! They transformed our commercial space with great efficiency and expertise. True professionals in every sense.`,
  },
];

export const blogs = [
  {
    title: "Top Construction Trends to Watch in 2024",
    content: `Stay ahead in the construction industry with the latest 2024 trends! From green building techniques to the newest in construction technology, learn how these trends can elevate your project’s quality and efficiency.`,
    image: blog1,
  },
  {
    title: "Why Sustainable Building Materials Matter in Modern Construction",
    content: `Discover the impact of sustainable building materials on the environment and learn how they enhance project quality, lower costs, and foster a healthier community. Explore sustainable choices that make a difference in construction.`,
    image: blog2,
  },
  {
    title: "Essential Project Management Tips for Construction Success",
    content: `Master the keys to effective project management with tips on scheduling, budgeting, and team communication. These strategies help construction projects stay on track, minimize delays, and ensure a smooth building process.`,
    image: blog3,
  },
  {
    title: "Choosing the Right Construction Partner for Your Project",
    content: `Choosing the right contractor can make or break your project. Discover what to look for in a construction partner, from reliability and expertise to budgeting and project handling.`,
    image: blog4,
  },
  {
    title: "How Smart Technology is Revolutionizing Construction",
    content: `Explore how smart technologies like AI, IoT, and advanced software tools are reshaping construction management, boosting efficiency, safety, and project timelines.`,
    image: blog5,
  },
  {
    title: "The Benefits of Renovating vs. Building New",
    content: `Wondering whether to renovate or build from scratch? Learn the advantages of both approaches and determine which is the best fit for your needs and budget.`,
    image: blog6,
  },
  {
    title: "Building for Energy Efficiency: Tips for Modern Construction",
    content: `Learn how energy-efficient building techniques can reduce costs and environmental impact, and increase the comfort of homes and commercial spaces.`,
    image: blog7,
  },
  {
    title: "Construction Safety: Key Practices for Safe Job Sites",
    content: `Safety is paramount in construction. Discover essential practices and innovations that protect workers and ensure a safe and compliant job site.`,
    image: blog8,
  },
  {
    title: "Understanding the Role of Architects and Engineers in Construction",
    content: `Gain insight into the vital roles that architects and engineers play in successful construction projects and how their collaboration leads to well-designed and sustainable structures.`,
    image: blog9,
  },
];

export const footer = [
  {
    title: "Company",
    routes: [
      {
        name: "About Us",
        href: "#about",
      },
      {
        name: "Careers",
        href: "#",
      },
      {
        name: "Contact Us",
        href: "#contact",
      },
    ],
  },
  {
    title: "Services",
    routes: [
      {
        name: "Residential Construction",
        href: "#services",
      },
      {
        name: "Commercial Projects",
        href: "#services",
      },
      {
        name: "Renovation & Remodeling",
        href: "#services",
      },
      {
        name: "Project Management",
        href: "#services",
      },
    ],
  },
  {
    title: "Resources",
    routes: [
      {
        name: "Blog",
        href: "#blog",
      },
      {
        name: "Case Studies",
        href: "#",
      },
      {
        name: "FAQs",
        href: "#faq",
      },
      {
        name: "Privacy Policy",
        href: "#",
      },
    ],
  },
];

export const socialHandles = [
  {
    name: "Instagram",
    icon: <FaInstagram />,
    link: "https://www.instagram.com/prima_consultancyconstructions",
  },
  {
    name: "LinkedIn",
    icon: <FaLinkedin />,
    link: "https://www.linkedin.com/company/prima-consultancy-and-construction",
  },
];
